var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:"cursor-pointer flex flex-col text-black w-full border",on:{"click":function($event){return _vm.onClickHeader()}}},[_c('div',{staticClass:"card-header  p-5"},[_c('div',{staticClass:"flex flex-col w-3/5 break-words"},[_c('div',{staticClass:"text-base font-semibold sm:h-12"},[_vm._v(" "+_vm._s(_vm.voucher.title || 'Gutscheintitel')+" ")]),_c('div',{staticClass:"text-sm"},[_vm._v(" Von "+_vm._s(_vm.voucher.from ? _vm.voucher.from : _vm.AUTH_USER.data.detail.fullname)+" ")])]),_vm._m(0)]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"w-full flex flex-col break-words"},[(_vm.userVoucher && _vm.userVoucher.custom_image)?_c('div',{staticClass:"w-2/5 p-1"},[_c('img',{staticClass:"card-image",attrs:{"src":_vm.onSetCustomImage(_vm.userVoucher.custom_image),"alt":""}})]):_c('div',[_c('div',{staticClass:"card-image",staticStyle:{"background-size":"cover"},style:({
            backgroundImage:
              'url(' +
              _vm.onSetCustomImage(
                _vm.voucher.data_json != null &&
                  _vm.voucher.data_json.background_image
                  ? _vm.voucher.data_json.background_image
                  : _vm.voucher.custom_background_image ||
                      _vm.voucher.background_image
              ) +
              ')',
            backgroundColor: 'white',
          })})])]),_c('div',{staticClass:"flex flex-col p-5 break-words card-description"},[_c('div',{staticClass:"text-xs leading-5"},[_vm._v(" "+_vm._s((_vm.voucher.note || _vm.voucher.description).length > 120 ? (_vm.voucher.note || _vm.voucher.description).slice(0, 120) + '...' : _vm.voucher.note || _vm.voucher.description || 'Beschreibung')+" ")])]),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"self-center w-2/5"},[_c('img',{staticClass:"card-logo ml-auto",attrs:{"src":require("@/_assets/img/epasnets-heart-light.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col p-5 bg-white"},[_c('div',{staticClass:"text-center w-full mb-2"},[_vm._v("epasnets... mit Liebe verschenkt!")]),_c('div',{staticClass:"text-2xs",staticStyle:{"color":"#ff5563 !important"}},[_vm._v("* Durch die Gestaltung dieses Gutscheins wurde ein Fixbetrag an von epasnets unterstützte Sozialprojekte in Tirol gespendet. ")])])}]

export { render, staticRenderFns }